@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.footer_n {
  width: 100%;
  padding: 10px;
  background-color: #302E2B;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.15);
  position: relative;
  bottom: 0;
  z-index: 1000;
}

.footer-bottom_n {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright_n {
  text-align: center;
  margin: 0 auto;
  padding: 5px;
  color: #ffffff;
}

.copyright_n a {
  color: #ffffff; 
  text-decoration: none; 
  font-weight: bold;
}

.footer-cards_n {
  box-sizing: border-box;
  width: 100%;
  background-color: #f9f9f9; /* Light background color */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  padding: 1.5rem 0;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.footer-card_n {
  box-sizing: border-box;
  width: 30px;
  height: auto;
}

.footer-cards_n::-webkit-scrollbar {
  display: none;
}

.floating-menu {
    position: fixed; 
    bottom: 0; 
    width: 100%; 
    background: #333; 
    color: #fff;
    height: 80px; 
    padding: 20px 10px;
    z-index: 1001;
    transition: transform 0.5s ease-in-out;
    transform: translateY(0);
    display: flex; 
    justify-content: space-around;
  }
  .floating-menu.hide {
    transform: translateY(100%);
  }
  .menu-icon {
    font-size: 30px; /* Adjust as needed */
  }
  
  .menu-text {
    font-size: 12px; /* Adjust as needed */
  }

  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .menu-item svg {
    width: 70px;  /* Adjust as needed */
    height: 70px; /* Adjust as needed */
  }

  .menu-item span {
    font-family: 'Roboto', sans-serif; /* Replace with your preferred font */
    font-size: 12px; /* Adjust as needed */
  }
  
  @media (max-width: 768px) {
    .floating-menu {
      display: flex; 
      justify-content: space-around;
      z-index: 1001;
    }
    .footer_n{
        padding-bottom: 0;
    }
    .footer-bottom_n {
      padding-bottom: 0; /* Add padding to the footer-bottom_n class */
    }
  }

  @media (min-width: 769px) {
    .floating-menu {
      display: none;
      z-index: 1001;
    }
    .footer_n{
      padding-bottom: 0;
  }
  .footer-bottom_n {
    padding-bottom: 0; /* Add padding to the footer-bottom_n class */
  }
  }
  .fraktal-link {
    color: gray;
    text-decoration: none;
  }