.comparison {
  transform: translateY(80px);
  transition: 0.3s ease-in-out;
}
.comparison.comparison-bar-open {
  transform: translateY(0);
}
.comparison .selected-products {
  position: absolute;
  top: -50px;
  right: calc(50% - 80px);
  border-radius: 12px;
  padding: 10px;
  border: 0;
  outline: 0;
}
.comparison-table {
  min-height: 100vh;
}
.comparison-table .row {
  overflow-x: auto;
  flex-wrap: nowrap;
}
.comparison-table .row .col-4 {
  min-width: 260px !important;
}
.comparison-table img {
  width: 240px;
  height: 320px;
  margin: 10px 0;
}
