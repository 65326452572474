@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.category-select {
    width: 75px; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  .modal-overlay {
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1000;
  }
  
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
    text-align: center;
  }
  
  .modal-close-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
  }
  
  .modal-form {
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
  }

  .likeModal {
    position: absolute;
    right: 0;
    top: 50px;
    width: 300px;
    max-height: 400px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow-y: auto;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    visibility: hidden;
  }
  
  
  .likeModal-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .likeModal-item:last-child {
    margin-bottom: 0;
  }
  
  .likeModal-item img {
    width: 50px !important;
    height: 50px !important;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .likeModal-item .title {
    font-size: 12px !important;
    font-weight: bold;
  }

  .likeModal-item .title a {
    color: #000;
    text-decoration: none;
  }
  
  .likeModal-item .price {
    font-size: 12px !important;
    color: #999;
  }


  /* @media (max-width: 768px) { 
    .logo-small {
      max-height: 40px; 
      width: auto;
    }
  }

  @media (max-width: 768px) { 
    .header-middle-container {
      margin-top: 5px;
      height: 40px; 
    }
  }
*/
.floating-menu {
  position: fixed; 
  bottom: 0; 
  width: 100%; 
  background: #333; 
  color: #fff;
  height: 80px; 
  padding: 20px 10px;
  z-index: 1006;
  transition: transform 0.5s ease-in-out;
  transform: translateY(0);
  display: flex; 
  justify-content: space-around;
  box-sizing: border-box;
}
.floating-menu.hide {
  transform: translateY(100%);
}
.menu-icon {
  font-size: 30px; /* Adjust as needed */
}

.menu-text {
  font-size: 12px; /* Adjust as needed */
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu-item svg {
  width: 70px;  /* Adjust as needed */
  height: 70px; /* Adjust as needed */
}

.menu-item span {
  font-family: 'Roboto', sans-serif; /* Replace with your preferred font */
  font-size: 12px; /* Adjust as needed */
}

@media (max-width: 768px) {
  .floating-menu {
    display: flex; 
    justify-content: space-around;
    z-index: 1006;
  }
  /* body{
      padding-bottom: 80px;
  } */
}

@media (min-width: 769px) {
  .floating-menu {
    display: none;
  }
}

@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .topbar-text {
    font-size: 12px; /* Adjust size as needed */
    z-index: 1000;
  }
}

.topbar-link {
  font-size: 12px; /* Adjust size as needed */
  font-family: 'Roboto', sans-serif; /* Use a modern font */
}

.liked-products {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.liked-products .liked-list {
  margin-right: 12px;
}

.liked-products .liked-list a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #eee;
  color: #555;
  font-size: 18px;
  text-align: center;
  position: relative;
}

.liked-products .liked-list .likeModal a {
  color: #555;
  border: none;
  border-radius: 0;
  width: 100%;
  height: auto;
  line-height: normal;
  font-size: 12px;
  text-align: left;

}

.liked-products .liked-list .likeModal a:hover {
  color: #555!important;
  background-color: transparent !important;
}

.liked-products .liked-list a:hover {
  color: #fff;
  background-color: #0B2875;
  border-color: transparent;
}

.liked-products .liked-list a .total-items {
  position: absolute;
  right: -6px;
  top: -5px;
  height: 19px;
  width: 19px;
  line-height: 19px;
  background-color: #081828;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  border-radius: 50%;
}

.liked-products .liked-list:hover .likeModal {
  opacity: 1;
  visibility: visible;
}

.liked-products .cart-items {
  position: relative;
}

.liked-products .cart-items:hover .main-btn {
  color: #fff;
  background-color: #0B2875;
  border-color: transparent;
}

.liked-products .cart-items .main-btn {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #eee;
  color: #555;
  font-size: 18px;
  text-align: center;
  position: relative;
}

.liked-products .cart-items .main-btn .total-items {
  position: absolute;
  right: -6px;
  top: -5px;
  height: 19px;
  width: 19px;
  line-height: 19px;
  background-color: #081828;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  border-radius: 50%;
}

.liked-products .cart-items:hover .shopping-item {
  opacity: 1;
  visibility: visible;
}

.liked-products .cart-items .shopping-item {
  position: absolute;
  top: 72px;
  right: 0;
  width: 300px;
  background: #fff;
  padding: 20px 25px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 99;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137);
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137);
  opacity: 0;
  visibility: hidden;
}

.no-liked {
  color: "#555";
}

@media (max-width: 767px) {
  .liked-products .cart-items .shopping-item {
    width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .liked-products .cart-items .shopping-item {
    top: 68px;
  }
}

@media (max-width: 767px) {
  .liked-products .cart-items .shopping-item {
    top: 66px;
  }
}

.liked-products .cart-items .shopping-item .dropdown-cart-header {
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e6e6e6;
}

.liked-products .cart-items .shopping-item .dropdown-cart-header span {
  text-transform: capitalize;
  color: #222;
  font-size: 14px;
  font-weight: 600;
}

.liked-products .cart-items .shopping-item .dropdown-cart-header a {
  float: right;
  text-transform: capitalize;
  color: #222;
  font-size: 14px;
  font-weight: 600;
}

.liked-products .cart-items .shopping-item .dropdown-cart-header a:hover {
  color: #0B2875;
}

.liked-products .cart-items .shopping-item .shopping-list li {
  overflow: hidden;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.liked-products .cart-items .shopping-item .shopping-list li .remove {
  position: absolute;
  right: 0;
  top: 0;
  height: 20px;
  width: 20px;
  line-height: 18px;
  text-align: center;
  background: #fff;
  color: #222;
  border-radius: 50%;
  font-size: 9px;
  border: 1px solid #ededed;
  padding-left: 1px;
}

.liked-products .cart-items .shopping-item .shopping-list li .remove:hover {
  border-color: transparent;
  color: #fff;
  background-color: #0B2875;
}

.liked-products .cart-items .shopping-item .shopping-list li .cart-img-head {
  width: 40%;
}

@media (max-width: 767px) {
  .liked-products .cart-items .shopping-item .shopping-list li .cart-img-head {
    width: 30%;
    margin-right: 12px;
  }
}

.liked-products .cart-items .shopping-item .shopping-list li .cart-img {
  border: 1px solid #ededed;
  overflow: hidden;
  height: 80px;
  width: 80px;
  border-radius: 4px;
  float: left;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .liked-products .cart-items .shopping-item .shopping-list li .cart-img {
    height: 60px;
    width: 60px;
    margin-right: 10px;
  }
}

.liked-products .cart-items .shopping-item .shopping-list li .content {
  padding-right: 25px;
  width: 60%;
}

@media (max-width: 767px) {
  .liked-products .cart-items .shopping-item .shopping-list li .content {
    width: 70%;
  }
}

.liked-products .cart-items .shopping-item .shopping-list li .content h4 {
  margin-bottom: 5px;
  line-height: 22px;
}

.liked-products .cart-items .shopping-item .shopping-list li .content h4 a {
  font-size: 14px;
  font-weight: 600;
  color: #081828;
}

@media (max-width: 767px) {
  .liked-products .cart-items .shopping-item .shopping-list li .content h4 a {
    font-size: 13px;
  }
}

.liked-products .cart-items .shopping-item .shopping-list li .content h4 a:hover {
  color: #0B2875;
}

.liked-products .cart-items .shopping-item .shopping-list li .content .quantity {
  line-height: 22px;
  font-size: 14px;
}

.liked-products .cart-items .shopping-item .bottom {
  text-align: center;
}

.liked-products .cart-items .shopping-item .bottom .total {
  overflow: hidden;
  display: block;
  padding-bottom: 10px;
}

.liked-products .cart-items .shopping-item .bottom .total span {
  text-transform: capitalize;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  float: left;
}

.liked-products .cart-items .shopping-item .bottom .total .total-amount {
  float: right;
  font-size: 14px;
}

.liked-products .cart-items .shopping-item .bottom .button {
  margin-top: 10px;
  width: 100%;
}

.liked-products .cart-items .shopping-item .bottom .button .btn {
  width: 100%;
}

.search-results {
  position: absolute;
  top: 100%;
  background-color: white;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 99;
}

.search-results li {
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
}

.search-results li:hover {
  background-color: #ddd;
}

.search-results a {
  color: black;
  text-decoration: none;
}

.search-results a:hover {
  color: gray;
}

.search-product-image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 10px;
}

.main-menu-item {
  color:#fff;
}

.main-menu-item:hover {
  color:#F5E10E !important;
}

.credit-item {
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  margin: 20px auto;
  padding: 20px;
  max-width: 600px;
  border-radius: 5px;
}

.credit-item table {
  width: 100%;
  border-collapse: collapse;
}

.credit-item th, .credit-item td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.credit-item th {
  text-align: left;
  background-color: #f4f4f4;
}

.credit-item input {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.credit-item button {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.credit-item button:hover {
  background-color: #0056b3;
}

.customer-name {
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.customer-name h1 {
  font-size: 24px;
}

.icon-container {
  position: relative;

}

.menu-text {
  font-size: 0.6em; /* Adjust as needed */

}