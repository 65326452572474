.filter {
  display: block;
  /* width: 80%; */
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
}

.filter h2 {
  font-size: 1.5em;
  text-align: center;
}

.filter-item {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 10px;
}

.filter-item label {
  margin-bottom: 5px;
  font-size: 0.9em;
}

.filter-item input,
.filter-item select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9em;
  font-family: "Roboto", sans-serif;
  color: #333;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.filter-item input:focus,
.filter-item select:focus {
  border-color: #007bff;
  outline: none;
}
