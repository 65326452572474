.hearth-button {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(128, 128, 128, 0.5); /* Adjust as needed */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 10px; /* Adjust as needed */
  }

  .compare-button {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(128, 128, 128, 0.5); /* Adjust as needed */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 10px; /* Adjust as needed */
  }

  /*.price{
    margin-top: 15px;
  }*/

  .main-price {
    font-size: 17px;
    font-weight: 700;
    color: #0B2875;
    display: inline-block;
  }
  
  .credit-price {
    color: gray;
    position: relative;
    margin-right: 10px;
    margin-bottom: 15px;
  }
  
  .credit-price::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    border-top: 2px solid gray;
    transform: rotate(-10deg); /* Tilt the strikethrough line */
    transform-origin: center;
  }