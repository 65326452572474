.menu-content {
    min-height: 50vh;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
    border-radius: 5px;
  }
  
  .menu-content h1,
  .menu-content h2,
  .menu-content h3,
  .menu-content h4,
  .menu-content h5,
  .menu-content h6 {
    color: #444;
    margin-top: 24px;
  }
  
  .menu-content p {
    margin: 16px 0;
  }
  
  .menu-content a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .menu-content a:hover {
    text-decoration: underline;
  }
  
  .menu-content img {
    max-width: 100%;
    height: auto;
  }