.no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  text-align: center;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  font-size: 24px;
  line-height: 20px;
  background-color: transparent;
  color: #0b2875;
  border: 1px solid #0b2875;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  z-index: 1003;
  transition: 0.3s ease-in-out;
}
.scroll-to-top:hover {
  background-color: #0b2875;
  color: #fff;
}

.filter-categories::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 12px;
}

.filter-categories::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #fff;
}

.filter-categories::-webkit-scrollbar-thumb {
  background-color: #0b2875;
  border-radius: 12px;
}

@media screen and (max-width: 768px) {
  .scroll-to-top {
    bottom: 100px;
  }
}
