.whatsapp-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 50px;
    height: 50px;
    z-index: 1002;
    background-color: #25D366;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible; 
  }
  
  .whatsapp-button img {
    width: 100%;
    height: 100%;
  }
  
  .extra-icons {
    position: absolute; /* position relative to the parent */
    bottom: 100%; /* position at the bottom */
    right: 0;
    display: flex;
    flex-direction: column;
    transform: translateY(5%); /* hide by moving down */
    transition: transform 0.3s ease;
    z-index: 2000; 
  }
  
  .extra-icons a {
    margin-bottom: 10px;
  }

  .col-lg-8.col-12.mb-2 {
    overflow: visible;
  }

  .col-lg-8.col-12.mb-2 img {
    max-width: 100%;
    height: auto;
}
  @media (max-width: 768px) {
    .whatsapp-button {
      display: none;
    }
  }
 /* Hover effect for web */
@media (hover: hover) and (pointer: fine) {
    .whatsapp-button:hover .extra-icons {
      transform: translateY(0); /* show by moving up */
    }
  }
  
  /* Click event for mobile */
  @media (hover: none) and (pointer: coarse) {
    .whatsapp-button.active .extra-icons {
      transform: translateY(0); /* show by moving up */
    }
  }
  .hero-area {
    margin-top: 3px; /* Adjust as needed */
  }

  @media (max-width: 768px) {
    .navigation {
      display: none;
    }
  }
  @media (max-width: 768px) {
    .hero-area .container {
      padding-left: 5px;  
      padding-right: 5px;
    }
  }
  .custom-swiper-image {
    max-width: 100%;
  }

  .flashy-border {
    border: 2px solid #FFD700;
    animation: flash-border 8s infinite;
  }
  
  @keyframes flash-border {
    0% { border-color: #FFD700; }
    50% { border-color: #0b2875; }
    100% { border-color: #FFD700; }
  }

  .flashy-background {
    animation: flash-background 8s infinite;
  }
  
  @keyframes flash-background {
    0% { background-color: #FFD700; }
    50% { background-color: #0b2875; }
    100% { background-color: #FFD700; }
  }

  .flashy-text {
    animation: flash-text 8s infinite;
  }
  
  @keyframes flash-text {
    0% { color: #0b2875; }
    50% { color: #FFD700; }
    100% { color: #0b2875; }
  }

  .chat-window {
    position: fixed;
    bottom: 20px;
    right: 100px;
    width: 300px;
    height: 400px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    z-index: 1001;
  }
  
  .chat-header {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chat-header button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    color: #fff; /* Adjust this color to match your header */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .chat-header button:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Subtle hover effect */
  }
  
  .chat-body {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust the space between messages */
    overflow-y: auto;
  }

  .message-bubble {
    background-color: #f0f0f0; /* Light grey background */
    border-radius: 20px; /* Rounded corners for bubble effect */
    padding: 10px 15px; /* Padding inside the bubble */
    max-width: 80%; /* Max width of a message */
    word-wrap: break-word; /* Ensure long words do not overflow */
  }

  .message-content {
    margin: 0;
    color: #333; /* Darker text color for better readability */
  }
  
  .message-date {
    margin: 5px 0 0; /* Space above the date */
    font-size: 0.8rem; /* Smaller font size */
    font-style: italic; /* Italic font style */
    color: #999; /* Faded text color */
    text-align: right; /* Align date to the right */
  }

  .operator-message {
    margin-top: 20px; /* Space above the operator message */
    text-align: center; /* Center the operator message */
    color: #666; /* Slightly faded text color */
  }
  
  .chat-footer {
    display: flex;
    padding: 10px;
    background-color: #f0f0f0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .chat-footer input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .chat-footer button {
    padding: 10px 15px; /* Adjust padding for a larger click area */
    background-color: #007bff; /* Primary color for the button */
    color: #ffffff; /* Text color for contrast */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Clear, visible text */
    cursor: pointer; /* Cursor changes to pointer to indicate it's clickable */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .chat-footer button:hover, .chat-footer button:focus {
    background-color: #0056b3; /* Darker shade on hover/focus for feedback */
    outline: none; /* Remove focus outline to maintain the design */
  }

  .status-circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%; /* Makes it a circle */
    margin-left: 5px;
  }
  
  .green {
    background-color: green;
  }
  
  .red {
    background-color: red;
  }