.product-images .main-img img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .ReactModal__Overlay  {
    position: relative;
    z-index: 999;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .videoModal {
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #ccc;
    outline: none;
    padding: 4rem;
    position: relative;
    width: 80%;
    height: 80%;
  }

  .videoModal button {
    position: absolute;
    right: 20px;
    top: 10px;
  }

  .price-container {
    display: flex;
    align-items: center;
  }
  
  .credit-price {
    color: gray;
    position: relative;
    margin-right: 10px;
    margin-bottom: 15px;
  }
  
  .credit-price::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    border-top: 2px solid gray;
    transform: rotate(-10deg); /* Tilt the strikethrough line */
    transform-origin: center;
  }

  /*.price {
    margin-top: 15px;
  }
  
  .price span {
    font-size: 17px;
    font-weight: 700;
    color: #0B2875;
    display: inline-block;
  }*/