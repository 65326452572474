body {
    font-family: 'SF Pro Display', sans-serif;
    background-color: #f4f4f4;
  }
  
  .credit-item {
    background-color: #fff;
    border: 1px solid #FFD700;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    margin: 20px;
    padding: 20px;
    /* max-width: 600px; */
    border-radius: 5px;
  }
  
  .credit-item table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .credit-item th, .credit-item td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .credit-item th {
    text-align: left;
    background-color: #FFD700;
  }
  
  .credit-item input {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .credit-item button {
    width: 40%;
    background-color:#0B2875;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .credit-item button:hover {
    background-color: #0056b3;
  }

  .customer-name {
    max-width: 600px;
    margin: 20px auto;
    text-align: center;
  }
  
  .customer-name h1 {
    font-size: 24px;
  }

  .input-currency-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .input-currency-wrapper input {
    padding-right: 30px; 
  }
  
  .currency-symbol {
    position: absolute;
    right: 15px;
    top: 40%;
    transform: translateY(-50%);
    font-size: 1.2em; /* Adjust this value */
    font-weight: bold;
  }
  
  .details-wrapper.visible {
    max-height: 500px; /* Adjust this value */
    transform: translateY(0);
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }